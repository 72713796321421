import React, { Component } from 'react';
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

export default class AppNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {isOpen: false};
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return <Navbar  style={{position: "sticky", position: "-webkit-sticky", top: 0}} variant="dark" sticky="top" color="dark" dark expand="md">
      <NavbarBrand tag={Link} style={{"font-size":"large"}} to="/">Bestellen</NavbarBrand>
      <NavbarBrand tag={Link} style={{"font-size":"large"}} to="/impressum">Impressum</NavbarBrand>
      {/*<NavbarToggler onClick={this.toggle}/>*/}
      {/* <Collapse isOpen={this.state.isOpen} navbar> */}
        <Nav className="ml-auto" navbar>
          <NavItem>
          <div className="float-right">
            <Button color="success" onClick={() => document.getElementById('bottomelement').scrollIntoView({ block: 'end',  behavior: 'smooth' })}>{this.props.quantity} | {this.props.total.toFixed(2)} €</Button>
          </div>
          </NavItem>
        </Nav>
      {/*</Collapse>*/}
    </Navbar>;
  }
}