import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { Table, Thead, Tbody, Tr, Td, Tfoot } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

class ArtikelEntry extends React.Component {
    constructor(props) {
      super(props);
      this.state = {comment: ''};
      
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.increaseQuantity = this.increaseQuantity.bind(this);
      this.decreaseQuantity = this.decreaseQuantity.bind(this);     
    }
  
    handleChange(event) {
      this.setState({comment: event.target.value});
    }
  
    handleSubmit(event) {
      this.props.onAddToCartChange(this.props.artikel.id, this.state.comment);
      //event.preventDefault();
    }

    increaseQuantity(event) {
      this.props.onIncreaseQuantity(this.props.artikel.id);
      //event.preventDefault();
    }

    decreaseQuantity(event) {
      this.props.onDecreaseQuantity(this.props.artikel.id);
      //event.preventDefault();
    }

    getArtikelQuantity(artikelId) {
      var index = this.props.bestellartikelArray.findIndex(x => x.id === artikelId);

      // Check if element exists
       if (index >= 0) {
        return this.props.bestellartikelArray[index].quantityOrder;
      }
      return 0;
    }

    getArtikelSum(artikelId, artikelPrice) {
        var quantity = this.getArtikelQuantity(artikelId);

        return artikelPrice * quantity;
    }
  
    render() {
        const artikel = this.props.artikel;

      return (    
        <Tr key={artikel.id}>
        <Td style={{whiteSpace: 'unset'}}>{artikel.name}</Td>
        <Td style={{whiteSpace: 'nowrap'}}>{artikel.price.toFixed(2)} €</Td>
        <Td>
          <ButtonGroup>
              <Button size="sm" color="danger" onClick={() => this.decreaseQuantity()}>-</Button>
              <div>&nbsp;{this.getArtikelQuantity(artikel.id)}&nbsp;</div>
              <Button size="sm" color="primary" onClick={() => this.increaseQuantity()}>+</Button>
          </ButtonGroup>
        </Td>
        <Td style={{whiteSpace: 'nowrap', fontWeight: 'bold'}}>{this.getArtikelSum(artikel.id, artikel.price).toFixed(2)} €</Td>
        </Tr>
      );
    }
    
  }

  export default ArtikelEntry;