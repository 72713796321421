import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import Preisliste from './Preisliste';
import Bestellbestaetigung from './Bestellbestaetigung';
import Stornierbestaetigung from './Stornierbestaetigung';
import Bestellung from './Bestellung';
import BestellungPayPal from './BestellungPayPal';
import Impressum from './Impressum';
import Bestelluebersicht from './Bestelluebersicht';


class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path='/' exact={true} component={Preisliste}/>
          <Route path='/bestellbestaetigung' exact={true} component={Bestellbestaetigung}/>
          <Route path='/stornierbestaetigung' exact={true} component={Stornierbestaetigung}/>
          <Route path='/bestellung' exact={true} component={Bestellung}/>
          <Route path='/bestellungpaypal' exact={true} component={BestellungPayPal}/>
          <Route path='/impressum' exact={true} component={Impressum}/>
          <Route path='/bestelluebersicht' exact={true} component={Bestelluebersicht}/>
        </Switch>
      </Router>
    )
  }
}

export default App;