import React, { Component } from 'react';
import './App.css';
import AppNavbarWithoutCart from './AppNavbarWithoutCart';
import { Button, ButtonGroup, Container } from 'reactstrap';

class BestellungPayPal extends Component {

  constructor(props) {
    super(props);
    this.state = {bestellung: null, isLoading: true, disabledConfirmButton: false, errorMessage: ""}

  }

  componentDidMount() {
    this.setState({isLoading: true});

    var guid;

    // Get PayPal IDs
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    var paymentId = params.get('paymentid');
    var payerId = params.get('payerid');
    var orderId = params.get('orderid');

    var response;
    
    fetch('https://manne.org:8080/bestellung/paypal/order?orderid=' + orderId + '&paymentid=' + paymentId + '&payerid=' + payerId, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'MVMFrontend123'
      }
    })
      .then(async response => {
          const data = await response.json();
          this.setState({bestellung: data, isLoading: false});
          // check for error response
          if (!response.ok) {
              // get error message from body or default to response status
              const error = response.status;
              return Promise.reject(error);
          }
    })
    .catch(console.error);
  }

  getArtikelQuantity(bestellung) {

    const artikelMap = new Map(); // artikelname, number
 
    bestellung.bestellartikel.map((bestellartikel) => {

      //alert(bestellartikel);
      console.log(bestellartikel);

      if (artikelMap.has(bestellartikel.name)) {
        var number = artikelMap.get(bestellartikel.name);
        artikelMap.set(bestellartikel.name, number + 1);
      } else {
        artikelMap.set(bestellartikel.name, 1);
      }

    });

  var artikelQuantityArray = [];
  var priceArray = [];
  artikelMap.forEach(function (item, index) {
    artikelQuantityArray.push([item, index]);
  });
    return artikelQuantityArray;
  }

  getTotal(bestellartikelList) {

    var total = 0;


    bestellartikelList.map(bestellartikel => {

      total += bestellartikel.price;

    });

    return total;
  }

  addJsonToLocalStorage(name, jsonObject) {
    localStorage.setItem(name, JSON.stringify(jsonObject));
  }

  clearCartWithoutReload() {
    this.addJsonToLocalStorage('cart', []);
    localStorage.setItem('total', 0); 
    localStorage.setItem('cartInitTimestamp', 0);
  }

  cancelPayPalPayment() {
    window.location.href = "https://manne.org?state=paymentfailed";
  }

  confirmPayPalPayment() {

    if (!this.state.disabledConfirmButton) {
      this.setState({disabledConfirmButton: true});

      // Get PayPal IDs
      let search = this.props.location.search;
      let params = new URLSearchParams(search);
      var paymentId = params.get('paymentid');
      var payerId = params.get('payerid');
      var orderId = params.get('orderid');
    
      // make POST call to server
      fetch('https://manne.org:8080/bestellung/paypal/confirm?orderid=' + orderId + '&paymentid=' + paymentId + '&payerid=' + payerId, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'MVMFrontend123'
        }
      }).then(async response => {
        const resp = await response.json();
        var data = resp.bestellung
        var orderSuccessful = false;
        console.log(data);
    
        // check for error response
        if (!response.ok) {
            this.setState({errorMessage: "Bestellung fehlgeschlagen! Bitte versuchen Sie es erneut."});
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // order successful
        //alert(JSON.stringify(data, null, 2));
        if (data === null) {
          orderSuccessful = false;
          window.location.href = resp.link;
        } else {
          orderSuccessful = true;
          this.clearCartWithoutReload();
          window.location.href = resp.link;
        }
    
      })
      .catch(function(err) {
          console.info(err + " url: ");
      });
    }
  }


  render() {
    const {bestellung, isLoading} = this.state;

    if (isLoading) {
      return <p>Daten werden geladen ...</p>;
    }

    var artikelQuantityList;
   if (bestellung != null) {
      artikelQuantityList = this.getArtikelQuantity(bestellung).map(artikelQuantity => {

        return (
        <li>
          {artikelQuantity[0] + " x " + artikelQuantity[1]}
        </li>
          ) 
    });
  }

    // check for PayPal callback
    var paymentState = -1; // -1 = no value given (normal cart), 0 = failed, 1 = booked (vorgemerkt), 2 = done, 3 = payment already done
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    if (params.get('state') == "paymentfailed") {
      paymentState = 0;
    } else if (params.get('state') == "paymentpossible") {
      paymentState = 1;
    } else if (params.get('state') == "success") {
      paymentState = 2;
    } else if (params.get('state') == "paymentalreadydone") {
      paymentState = 3;
    }

    return (
      <div>
        <AppNavbarWithoutCart/>
        <Container fluid>
        {bestellung == null && 
          <div>
            <div>Die Bestellung existiert nicht!</div>
          </div>
        }
        {bestellung != null && 
          <div>
            {paymentState == 1 &&
              <div class="alert alert-warning" role="alert">
                Die Bestellung wird erst aufgegeben, wenn Sie diese mit "Bezahlung via PayPal durchführen" bestätigen!
              </div>
            }
            {paymentState == 2 &&
              <div class="alert alert-success" role="alert">
                Die Bezahlung wurde erfolgreich durchgeführt und Ihre Bestellung wurde entgegengenommen!
              </div>
            }
            {paymentState == 3 &&
              <div class="alert alert-primary" role="alert">
                Die Bestellung wurde bereits erfolgreich bezahlt. Sie brauchen nichts Weiteres zu tun.
              </div>
            }
            <div>Bestellnummer: {bestellung.id}</div>
            {bestellung.besteller != null && bestellung.besteller != "" && bestellung.besteller.vorname != null && bestellung.besteller.vorname != "" && bestellung.besteller.nachname != null && bestellung.besteller.nachname != "" &&
            <div>Name: {bestellung.besteller.vorname} {bestellung.besteller.nachname}</div>

            }
            {bestellung.besteller != null && bestellung.besteller != "" && bestellung.besteller.mail != null && bestellung.besteller.mail != "" &&
            <div>E-Mail <small> (für den Ticketversand)</small>: {bestellung.besteller.mail} </div>

            }
            {bestellung.comment != null && bestellung.comment != "" &&
            <div>Kommentar: {bestellung.comment}</div>
            }
            <br></br>
            <div>Zusammenfassung:</div>
            <ul>
              {artikelQuantityList}
            </ul>
            <p>
              Summe: {this.getTotal(bestellung.bestellartikel).toFixed(2)} €
            </p>
            <h3 className="error"> { this.state.errorMessage } </h3>            
            {paymentState < 2 &&
            <div>
            <ButtonGroup className="mr-2" style={{'margin-top': '10px'}}>
            <Button size="m" color="danger" onClick={() => this.cancelPayPalPayment()}>Bestellung abbrechen</Button>
            </ButtonGroup>
            <ButtonGroup className="mr-2" style={{'margin-top': '10px'}}>
            <Button size="m" color="success" disabled={this.state.disabledConfirmButton} onClick={() => this.confirmPayPalPayment()}>Bezahlung via PayPal durchführen</Button>
            </ButtonGroup>
            </div>
            }
          </div>
        }
        </Container>
      </div>
    );
  }
}

export default BestellungPayPal;