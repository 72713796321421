import React, { Component } from 'react';
import './App.css';
import AppNavbarWithoutCart from './AppNavbarWithoutCart';
import { Button, ButtonGroup, Container } from 'reactstrap';

class Bestellung extends Component {

  constructor(props) {
    super(props);
    this.state = {bestellung: null, isLoading: true, stornoEnabled: false, errorMessage: ""}

    this.cancelOrder = this.cancelOrder.bind(this);     
  }

  componentDidMount() {
    this.setState({isLoading: true});

    var guid;

    // hacky parsing of query parameter
    var allQueryParams = this.props.location.search.replace("?", "").split("=");
    for (var i = 0; i < allQueryParams.length; i++) {
      if (allQueryParams[i] === "guid") {
        guid = allQueryParams[i+1];
      }
    }

      // get server settings
      fetch('https://manne.org:8080/settings', {
        method: 'GET',
        headers: {
          'Authorization': 'MVMFrontend123'
        },
        }).then(async response => {
          const settingsData = await response.json();
  
          // check for error response
          if (!response.ok) {
              // get error message from body or default to response status
              const error = (settingsData && settingsData.message) || response.status;
              return Promise.reject(error);
          }
          // request successful
          this.setState({stornoEnabled: settingsData.stornoEnabled})

      })
      .catch(error => {
          console.error('There was an error!', error);
      });

    var response;
    fetch('https://manne.org:8080/bestellung?guid=' + guid)
      .then(async response => {
        if (response.status == "204") {
          this.setState({isLoading: false});
          this.setState({bestellung: null});
        } else {
          const data = await response.json();
          this.setState({bestellung: data, isLoading: false});
          // check for error response
          if (!response.ok) {
              // get error message from body or default to response status
              const error = response.status;
              return Promise.reject(error);
          }
        }
    })
    .catch(console.error);
  }

  cancelOrder() {
    var stornoSuccessful = false;
    if (this.state.bestellung != null) {
      fetch('https://manne.org:8080/bestellung/delete?guid=' + this.state.bestellung.guid, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      }).then(async response => {
          if (response.status == "403") {
            this.setState({errorMessage: "Die Bestellung kann leider nicht mehr storniert werden! Der Stornozeitpunkt ist bereits vorbei."});
          } else {
            stornoSuccessful = true;
            this.props.history.push({
            pathname: '/stornierbestaetigung',
            state: {
              stornoSuccessful
             }
            })

            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                const error = response.status;
                return Promise.reject(error);
            }
          }
      })
    .catch(error => {
        console.error('There was an error!', error);
    });
    }
  }

  getArtikelQuantity(bestellung) {

    const artikelMap = new Map(); // artikelname, number
 
    bestellung.bestellartikel.map((bestellartikel) => {

      if (artikelMap.has(bestellartikel.name)) {
        var number = artikelMap.get(bestellartikel.name);
        artikelMap.set(bestellartikel.name, number + 1);
      } else {
        artikelMap.set(bestellartikel.name, 1);
      }

    });

  var artikelQuantityArray = [];
  var priceArray = [];
  artikelMap.forEach(function (item, index) {
    artikelQuantityArray.push([item, index]);
  });
    return artikelQuantityArray;
  }

  getTotal(bestellartikelList) {

    var total = 0;


    bestellartikelList.map(bestellartikel => {

      total += bestellartikel.price;

    });

    return total;
  }


  render() {
    const {bestellung, isLoading} = this.state;

    if (isLoading) {
      return <p>Daten werden geladen ... Think green: um Ressourcen zu sparen und die Umwelt zu schonen, schalten wir unseren Server bei längerem Nichtgebrauch ab. Deshalb kann es bis zu 2 Minuten dauern, bis hier Daten angezeigt werden.</p>;
    }

    var artikelQuantityList;
   if (bestellung != null) {
      artikelQuantityList = this.getArtikelQuantity(bestellung).map(artikelQuantity => {

        return (
        <li>
          {artikelQuantity[0] + " x " + artikelQuantity[1]}
        </li>
          ) 
    });
  }

    return (
      <div>
        <AppNavbarWithoutCart/>
        <Container fluid>
        {bestellung == null && 
          <div>
            <div>Die Bestellung existiert nicht oder wurde bereits storniert!</div>
          </div>
        }
        {bestellung != null && 
          <div>
            <div>Bestellnummer: {bestellung.id}</div>
            <div>Name: {bestellung.besteller.vorname} {bestellung.besteller.nachname}</div>
            {bestellung.comment != null && bestellung.comment != "" &&
            <div>Kommentar: {bestellung.comment}</div>
            }
            {bestellung.bestellungStatus != null &&
            <div>Bezahlstatus: {bestellung.bestellungStatus == "BEZAHLT" ? "Bezahlt" : bestellung.bestellungStatus == "BEZAHLT_PAYPAL" ? "Bezahlt via PayPal" : "Noch nicht bezahlt"}</div>
            }
            <br></br>
            <div>Zusammenfassung:</div>
            <ul>
              {artikelQuantityList}
            </ul>
            <p>
              Summe: {this.getTotal(bestellung.bestellartikel).toFixed(2)} €
            </p>
            <h3 className="error"> { this.state.errorMessage } </h3>
            {this.state.stornoEnabled &&
            <ButtonGroup>
            <Button size="sm" color="danger" onClick={() => this.cancelOrder()}>Bestellung unwiderruflich stornieren</Button>
            </ButtonGroup>
            }
          </div>
        }
        </Container>
      </div>
    );
  }
}

export default Bestellung;