import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { withRouter } from 'react-router-dom';
import AppNavbarWithoutCart from './AppNavbarWithoutCart';

class Bestelluebersicht extends React.Component {

  constructor(props) {
    super(props);
    this.state = {preisliste: [], password: "", requireLogin: true, isLoading: true, hideContactData: false, hideBulkOrder: false, hidePayedStornoOrder: false, filter: "", bestellungSummaryArray: [], paymentDialogOrderId: -1, showPaymentDialog: false, stornoDialogOrderId: -1, showStornoDialog: false, errorMessage: ""};
    //this.remove = this.remove.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleAusgabeStatus = this.handleAusgabeStatus.bind(this);
    this.handleEinlassstatus = this.handleEinlassstatus.bind(this);
  }

  componentDidMount() {
    this.setState({isLoading: true});

        // get server settings
        fetch('https://manne.org:8080/settings', {
          method: 'GET',
          headers: {
            'Authorization': 'MVMFrontend123'
          },
          }).then(async response => {
            const settingsData = await response.json();
    
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                const error = (settingsData && settingsData.message) || response.status;
                return Promise.reject(error);
            }        


        })
        .catch(error => {
            console.error('There was an error!', error);
        });

    fetch('https://manne.org:8080/bestellung/all', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('password')
      }
    }).then(async response => {
      const data = await response.json();
      this.setState({isLoading: false});
      // check for error response
      if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          if (response.status == 403) {
            this.setState({requireLogin: true});
          }
          return Promise.reject(error);
      } else {
        this.setState({bestellungSummaryArray: data, requireLogin: false});        
      }
  })
  .catch(error => {
      console.error('There was an error!', error);
  });
  }

  // status: 0 = OFFEN, 1 = AUSGEGEBEN
  setBestellungAusgabeStatus(orderid, status) {

    fetch('https://manne.org:8080/bestellung/ausgabestatus?id=' + orderid + "&status=" + status, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('password')
      }
    }).then(async response => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      } else {
        if (data != null) {
          var bestellungSummaryArray = this.state.bestellungSummaryArray;
    
          for (var i = 0; i < bestellungSummaryArray.length; i++) {
            if (bestellungSummaryArray[i].id == orderid) {
              bestellungSummaryArray[i].bestellungAusgabeStatus = data.bestellungAusgabeStatus;
              break;
            }
          }
          this.setState({bestellungSummaryArray: bestellungSummaryArray});
        }
      }
  })
  .catch(error => {
      console.error('There was an error!', error);
  });
   
  }

  // status: 0 = OFFEN, 1 = EINGELASSEN
  setBestellartikelEinlassstatus(bestellartikelid, status) {

    fetch('https://manne.org:8080/bestellung/bestellartikel/einlassstatus?id=' + bestellartikelid + "&status=" + status, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('password')
      }
    }).then(async response => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      } else {
        if (data != null) {
          var bestellungSummaryArray = this.state.bestellungSummaryArray;
    
          for (var i = 0; i < bestellungSummaryArray.length; i++) {
            for (var k = 0;  k < bestellungSummaryArray[i].bestellartikel.length; k++) {
              if (bestellungSummaryArray[i].bestellartikel[k].id == bestellartikelid) {
                bestellungSummaryArray[i].bestellartikel[k].einlassstatus = data.einlassstatus;
                break;
              }
            }
          }
          this.setState({bestellungSummaryArray: bestellungSummaryArray});
        }
      }
  })
  .catch(error => {
      console.error('There was an error!', error);
  });
   
  }

  handleFilterChange(event) {
    this.setState({filter: event.target.value});

  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
      errorMessage: ""
    });

  }

  
  handleAusgabeStatus(orderid, event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    if (value) {
      this.setBestellungAusgabeStatus(orderid, 1);
    } else {
      this.setBestellungAusgabeStatus(orderid, 0);
    }
  }

  handleEinlassstatus(bestellartikelid, event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    if (value) {
      this.setBestellartikelEinlassstatus(bestellartikelid, 1);
    } else {
      this.setBestellartikelEinlassstatus(bestellartikelid, 0);
    }
  }

  handlePasswordChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      password: value
    });

  }

  getArtikelString(bestellartikelList) {

    const artikelMap = new Map(); // artikelname, number

    var artikelString = "";


    bestellartikelList.map((bestellartikel) => {

      if (artikelMap.has(bestellartikel.name)) {
        var number = artikelMap.get(bestellartikel.name);
        artikelMap.set(bestellartikel.name, number + 1);
      } else {
        artikelMap.set(bestellartikel.name, 1);
      }

    });

    artikelMap.forEach(function (item, index) {
      artikelString += item + " x " + index + " | ";
    });

    return artikelString.substring(0, artikelString.length - 3);
  }

  getArtikelQuantity(bestellungSummaryArray) {

    const artikelMap = new Map(); // artikelname, number

    var artikelString = "";

    bestellungSummaryArray.map((bestellung) => {

      bestellung.bestellartikel.map((bestellartikel) => {

        if (artikelMap.has(bestellartikel.name)) {
          var number = artikelMap.get(bestellartikel.name);
          artikelMap.set(bestellartikel.name, number + 1);
        } else {
          artikelMap.set(bestellartikel.name, 1);
        }

    });

  });

  var artikelQuantityArray = [];
  artikelMap.forEach(function (item, index) {
    artikelQuantityArray.push([item, index]);
  });
    return artikelQuantityArray;
  }

  
  getArtikelEinlassstatus(bestellungSummaryArray) {

    const artikelMap = new Map(); // artikelname, number

    var artikelString = "";

    bestellungSummaryArray.map((bestellung) => {

      bestellung.bestellartikel.map((bestellartikel) => {

        if (bestellartikel.einlassstatus !== "OFFEN") {
          if (artikelMap.has(bestellartikel.name)) {
            var number = artikelMap.get(bestellartikel.name);
            artikelMap.set(bestellartikel.name, number + 1);
          } else {
            artikelMap.set(bestellartikel.name, 1);
          }
        }

    });

  });

  var artikelQuantityArray = [];
  artikelMap.forEach(function (item, index) {
    artikelQuantityArray.push([item, index]);
  });
    return artikelQuantityArray;
  }

  getTotal(bestellartikelList) {

    var total = 0;


    bestellartikelList.map(bestellartikel => {

      total += bestellartikel.price;

    });

    return total;
  }

  filter(bestellungSummary) {
    // hide bulk order if set
    if (this.state.hideBulkOrder) {
      if (bestellungSummary.bestellerInfo.toLowerCase().includes("Vorverkauf Bulk".toLowerCase())) {
        return false;
      }
    }
    if (this.state.hidePayedStornoOrder) {
      if (bestellungSummary.bestellungStatus == "BEZAHLT" || bestellungSummary.bestellungStatus == "BEZAHLT_PAYPAL" || bestellungSummary.bestellungStatus == "STORNIERT") {
        return false;
      }
    }
    if (bestellungSummary.bestellerInfo.toLowerCase().includes(this.state.filter.toLowerCase()) || (bestellungSummary.id).toString().toLowerCase().includes(this.state.filter.toLowerCase())) {
      return true;
    }
    for (var i = 0; i < bestellungSummary.bestellartikel.length; i++) {
      if (bestellungSummary.bestellartikel[i].tinyUid.toLowerCase().includes(this.state.filter.toLowerCase())) {
        return true;
      }
    }
    return false;
  }

  // Considers only the orders with the given filterStatus. filterStatus: 1 - BEZAHLT/BEZAHLT_PAYPAL, 2 - BESTELLT
  filterBestellungStatus(filterStatus, bestellungSummary) {
    if (filterStatus == 1) {
        if (bestellungSummary.bestellungStatus == "BEZAHLT" || bestellungSummary.bestellungStatus == "BEZAHLT_PAYPAL") {
          return true;
        }
        return false;
    } else if (filterStatus == 2) {
      if (bestellungSummary.bestellungStatus == "BESTELLT") {
        return true;
      }
      return false;
    } else if (filterStatus == 3) {
      // disable filtering with filterStatus 3
      return true;
    }
    return false;
  }

  addPassword() {
    localStorage.setItem('password', this.state.password);
    window.location.reload();
  }

  deletePassword() {
    localStorage.setItem('password', "");
    window.location.reload();
  }

  toggleContactDataVisibility() {
    this.setState({hideContactData: !this.state.hideContactData});
  }

  toggleBulkOrderVisibility() {
    this.setState({hideBulkOrder: !this.state.hideBulkOrder});
  }

  togglePayedStornoOrderVisibility() {
    this.setState({hidePayedStornoOrder: !this.state.hidePayedStornoOrder});
  }

  bestellungAusgabeStatusChecked(status) {
    if (status == null || status == "OFFEN") {
      return false;
    } else {
      return true;
    }
  }

  bestellartikelEinlassstatusChecked(status) {
    if (status == null || status == "OFFEN") {
      return false;
    } else {
      return true;
    }
  }

  doPayment(orderid) {
    
    fetch('https://manne.org:8080/bezahlung?id=' + orderid, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('password')
      }
    }).then(async response => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      } else {
        if (data != null) {
          var bestellungSummaryArray = this.state.bestellungSummaryArray;
    
          for (var i = 0; i < bestellungSummaryArray.length; i++) {
            if (bestellungSummaryArray[i].id == orderid) {
              bestellungSummaryArray[i].bestellungStatus = data.bestellungStatus;
              break;
            }
          }
          this.setState({paymentDialogOrderId: -1, bestellungSummaryArray: bestellungSummaryArray});
        }
      }
  })
  .catch(error => {
      console.error('There was an error!', error);
  });
   
  }

  doStorno(orderid) {
    
    fetch('https://manne.org:8080/bestellung/storno?id=' + orderid, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('password')
      }
    }).then(async response => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      } else {
        if (data != null) {
          var bestellungSummaryArray = this.state.bestellungSummaryArray;
    
          for (var i = 0; i < bestellungSummaryArray.length; i++) {
            if (bestellungSummaryArray[i].id == orderid) {
              bestellungSummaryArray[i].bestellungStatus = data.bestellungStatus;
              break;
            }
          }
          this.setState({stornoDialogOrderId: -1, bestellungSummaryArray: bestellungSummaryArray});
        }
      }
  })
  .catch(error => {
      console.error('There was an error!', error);
  });
   
  }

  showPaymentDialog(bestellungSummaryId) {
    this.setState({paymentDialogOrderId: bestellungSummaryId, showPaymentDialog: true});
  }

  showStornoDialog(bestellungSummaryId) {
    this.setState({stornoDialogOrderId: bestellungSummaryId, showStornoDialog: true});
  }

  countEintrittskarten(bestellartikelArray) {
    var numberOfEintrittskarten = 0;

    for (var i = 0; i < bestellartikelArray.length; i++) {

      // get type for artikel with given ID
      var artikelIndex = this.state.preisliste.findIndex(x => x.id === bestellartikelArray[i].id);
      var artikelType = this.state.preisliste[artikelIndex].artikelType;
      
      if (artikelType === "EINTRITT") {
        numberOfEintrittskarten += bestellartikelArray[i].quantityOrder;
      }
    }
    return numberOfEintrittskarten;
  }

  countArtikelUngleichEntrittskarten(bestellartikelArray) {
    var numberOfArtikelUngleichEintrittskarten = 0;

    for (var i = 0; i < bestellartikelArray.length; i++) {

      // get type for artikel with given ID
      var artikelIndex = this.state.preisliste.findIndex(x => x.id === bestellartikelArray[i].id);
      var artikelType = this.state.preisliste[artikelIndex].artikelType;
      
      if (artikelType !== "EINTRITT") {
        numberOfArtikelUngleichEintrittskarten += bestellartikelArray[i].quantityOrder;
      }
    }
    return numberOfArtikelUngleichEintrittskarten;
  }

  render() {
    const {bestellungSummaryArray, isLoading, requireLogin} = this.state;

    var passwordLocalStorage = localStorage.getItem('password');
    if (passwordLocalStorage === null) {
      passwordLocalStorage = ""
    }

    if (passwordLocalStorage === "" || requireLogin == true) {
      return (
        <div>
          <AppNavbarWithoutCart/>
          <Container fluid>
           <input type="password" placeholder="Passwort" value={this.state.password} onChange={this.handlePasswordChange}  />
           <p></p>
           <Button size="l" color="success" onClick={() => this.addPassword()}>Einloggen</Button>
           <p></p>
           <Button size="l" color="danger" onClick={() => this.deletePassword()}>Ausloggen</Button>
          </Container>
        </div>
      );
    }

    const handlePaymentDialogCancel = () => {
      this.setState({paymentDialogOrderId: -1, showPaymentDialog: false});
    }

    const handlePaymentDialogConfirm = () => {
      this.setState({showPaymentDialog: false});
      this.doPayment(this.state.paymentDialogOrderId);
      }

      const handleStornoDialogCancel = () => {
        this.setState({stornoDialogOrderId: -1, showStornoDialog: false});
      }
  
      const handleStornoDialogConfirm = () => {
        this.setState({showStornoDialog: false});
        this.doStorno(this.state.stornoDialogOrderId);
        }

    if (isLoading) {
      return <p>Daten werden geladen ...</p>
    }

    if (bestellungSummaryArray != null) {

      var bestellungSummaryArrayFiltered = bestellungSummaryArray.filter(bestellungSummary => this.filter(bestellungSummary));

    
      const bestellungSummaryList = bestellungSummaryArrayFiltered.map(bestellungSummary => {

        const bestellartikelList = bestellungSummary.bestellartikel.map(bestellartikel => {
            return (
              <div>                
                <p><input type="checkbox" checked={this.bestellartikelEinlassstatusChecked(bestellartikel.einlassstatus)} onChange={(e) => this.handleEinlassstatus(bestellartikel.id, e)} /> {bestellartikel.name}<br></br>{bestellartikel.tinyUid}</p>
              </div>
            );
          });

          return (
            <Tr>
            <Td class="wrapword" style={{border: '1px solid'}}>{bestellungSummary.id}</Td>
            <Td class="wrapword" style={{border: '1px solid'}}>{new Date(bestellungSummary.bestelldatum).toLocaleDateString("de-DE") + " " + new Date(bestellungSummary.bestelldatum).toLocaleTimeString("de-DE")}</Td>
            <Td class="wrapword" style={{whiteSpace:'pre-line', border: '1px solid'}}>{this.state.hideContactData ? "ausgeblendet" : bestellungSummary.bestellerInfo}</Td>
            <Td class="wrapword" style={{border: '1px solid'}}>{bestellartikelList}</Td>
            <Td class="wrapword" style={{border: '1px solid'}}>{bestellungSummary.comment} </Td>
            <Td class="wrapword" style={{border: '1px solid'}}>{this.getTotal(bestellungSummary.bestellartikel).toFixed(2)} €</Td>
            
            <Td class="wrapword" style={{border: '1px solid'}}>
              {bestellungSummary.bestellungStatus == "BESTELLT" &&<Button size="sm" color="primary" onClick={(e) => this.showPaymentDialog(bestellungSummary.id)}>Bezahlung bestätigen</Button>}
              {bestellungSummary.bestellungStatus != "BESTELLT" && bestellungSummary.bestellungStatus != null ? bestellungSummary.bestellungStatus.toString().replace("_", " ") : ""}            
              <br></br>
              {bestellungSummary.bestellungStatus != "STORNIERT" &&<Button size="sm" color="primary" onClick={(e) => this.showStornoDialog(bestellungSummary.id)}>Stornieren</Button>}

            </Td>
           </Tr>
          )
    });

      // returns only Artikel with BestellungStatus BEZAHLT
      const artikelQuantityList = this.getArtikelQuantity(bestellungSummaryArray.filter(bestellungSummary => this.filterBestellungStatus(1, bestellungSummary))).map(artikelQuantity => {
        return (
            <Tr>
            <Td class="wrapword" style={{border: '1px solid'}}>{artikelQuantity[1]}</Td>
            <Td class="wrapword" style={{border: '1px solid'}}>{artikelQuantity[0]}</Td>
           </Tr>
          )
      });

      // returns only Artikel with BestellungStatus BEZAHLT
      const artikelQuantityListUnpayed = this.getArtikelQuantity(bestellungSummaryArray.filter(bestellungSummary => this.filterBestellungStatus(2, bestellungSummary))).map(artikelQuantity => {
        return (
            <Tr>
            <Td class="wrapword" style={{border: '1px solid'}}>{artikelQuantity[1]}</Td>
            <Td class="wrapword" style={{border: '1px solid'}}>{artikelQuantity[0]}</Td>
            </Tr>
          )
      });

      // returns only Artikel with Einlassstatus != OFFEN
      const artikelQuantityEingelassen = this.getArtikelEinlassstatus(bestellungSummaryArray.filter(bestellungSummary => this.filterBestellungStatus(3, bestellungSummary))).map(artikelQuantity => {
        return (
            <Tr>
            <Td class="wrapword" style={{border: '1px solid'}}>{artikelQuantity[1]}</Td>
            <Td class="wrapword" style={{border: '1px solid'}}>{artikelQuantity[0]}</Td>
            </Tr>
          )
      });

      return (
        <div>
          <AppNavbarWithoutCart/>
          <Container fluid>
          <Modal isOpen={this.state.showPaymentDialog} fade={false}
                       style={{display: "block"}}>
                    <ModalHeader>
                        Bezahlung bestätigen
                    </ModalHeader>
                    <ModalBody>
                        Möchten Sie die Bezahlung für die Bestellnummer {this.state.paymentDialogOrderId} bestätigen und die Tickets per Mail versenden?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={handlePaymentDialogCancel}>
                            Abbrechen
                        </Button>{' '}
                        <Button color="success" onClick={handlePaymentDialogConfirm}>Bezahlung bestätigen</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.showStornoDialog} fade={false}
                       style={{display: "block"}}>
                    <ModalHeader>
                        Stornierung bestätigen
                    </ModalHeader>
                    <ModalBody>
                        Möchten Sie die Bestellung {this.state.stornoDialogOrderId} wirklich stornieren und eine Stornierungsmail versenden? Bitte ggf. um die Rückzahlung kümmern. 
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={handleStornoDialogCancel}>
                            Abbrechen
                        </Button>{' '}
                        <Button color="success" onClick={handleStornoDialogConfirm}>Stornierung bestätigen</Button>
                    </ModalFooter>
                </Modal>
          <input type="text" placeholder="Filter Name, Ticket, ..." value={this.state.filter} onChange={this.handleFilterChange}  />
          <Button style={{float: 'right'}} size="s" color="danger" onClick={() => this.deletePassword()}>Ausloggen</Button>
          <p></p>
          <Button style={{float: 'right'}} size="s" color="info" onClick={() => this.toggleContactDataVisibility()}>{this.state.hideContactData ? "Besteller einblenden" : "Besteller ausblenden"}</Button>
          <p></p>
          <Button style={{float: 'right'}} size="s" color="info" onClick={() => this.toggleBulkOrderVisibility()}>{this.state.hideBulkOrder ? "Bulk-Bestellungen einblenden" : "Bulk-Bestellungen ausblenden"}</Button>
          <p></p>
          <Button style={{float: 'right'}} size="s" color="info" onClick={() => this.togglePayedStornoOrderVisibility()}>{this.state.hidePayedStornoOrder ? "Bez. & stornierte Bst. einblenden" : "Bez. & stornierte Bst. ausblenden"}</Button>
          <p></p>
          <h3>Bestellübersicht</h3>
            <Table className="mt-4" style={{'table-layout':'fixed'}}>
              <Thead>
              <Tr>
                <Th width="5%">Bestellnr.</Th>
                <Th width="5%">Datum</Th>
                <Th width="30%">Besteller</Th>
                <Th width="20%">Ticket</Th>
                <Th width="20%">Kommentar</Th>
                <Th width="10%">Summe</Th>
                <Th width="10%">Bezahlung bestätigen</Th>
              </Tr>
              </Thead>
              <Tbody>
              {bestellungSummaryList}
              </Tbody>
            </Table>
            <p></p>
            <h4>Mengenübersicht bezahlte Artikel</h4>
            <Table className="mt-4" style={{'table-layout':'auto'}}>
              <Thead>
              <Tr>
              <Th width="80%">Artikelbezeichnung</Th>
              <Th width="20%">Menge</Th>
              </Tr>
              </Thead>
              <Tbody>
              {artikelQuantityList}
              </Tbody>
            </Table>
            <p></p>
            <h4>Mengenübersicht bestellte aber unbezahlte Artikel</h4>
            <Table className="mt-4" style={{'table-layout':'auto'}}>
              <Thead>
              <Tr>
              <Th width="80%">Artikelbezeichnung</Th>
              <Th width="20%">Menge</Th>
              </Tr>
              </Thead>
              <Tbody>
              {artikelQuantityListUnpayed}
              </Tbody>
            </Table>
            <h4>Mengenübersicht Eingelassen</h4>
            <Table className="mt-4" style={{'table-layout':'auto'}}>
              <Thead>
              <Tr>
              <Th width="80%">Artikelbezeichnung</Th>
              <Th width="20%">Menge</Th>
              </Tr>
              </Thead>
              <Tbody>
              {artikelQuantityEingelassen}
              </Tbody>
            </Table>
          </Container>
        </div>
      );
    } else {
      return (
        <div>
          Der Bestellzeitraum ist leider abgelaufen!
        </div>
          );
    }
  }
}

export default withRouter(Bestelluebersicht);