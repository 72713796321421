import React from 'react';
import AppNavbarWithoutCart from './AppNavbarWithoutCart';

class Stornierbestaetigung extends React.Component {
    constructor(props) {
      super(props);
    }

    render() {
      let message;
      if (this.props.location.state.stornoSuccessful) {
        message = "Die Bestellung wurde erfolgreich storniert!";
      } else {
        message = "Die Bestellung konnte leider nicht storniert werden. Bitte versuchen Sie es erneut!";
      }
      return (
        <div>
        <AppNavbarWithoutCart/>
        <div>{message}</div>
      </div>
      );
    }

}

export default Stornierbestaetigung;