import React from 'react';
import AppNavbarWithoutCart from './AppNavbarWithoutCart';
import { Container } from 'reactstrap';

class Bestellbestaetigung extends React.Component {
    constructor(props) {
      super(props);
    }

    render() {
      let message;
      if (this.props.location.state.orderSuccessful) {
        message = "Wir haben Ihre Bestellung mit der Bestellnummer " + this.props.location.state.data.id + " erfolgreich entgegengenommen! Bitte folgen Sie den Anweisungen in der Bestellbestätigungs-Mail. Sollten Sie diese Mail nicht erhalten haben, so prüfen Sie bitte Ihren Spam-Ordner. Sie können sich im Problemfall auch gerne per E-Mail an vorstand1@sg-mettenberg.de oder per Telefon an 01774344755 wenden.";
      } else {
        message = "Bestellung fehlgeschlagen! Bitte versuchen Sie es erneut.";
      }
      return (
        <div>
        <AppNavbarWithoutCart/>
        <Container fluid>
          <div>{message}</div>
        </Container>
      </div>
      );
    }

}

export default Bestellbestaetigung;